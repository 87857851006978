<template>
  <el-link v-if="isindex==0"
           :href="to"
           target="_blank"
           :underline="false">
    <slot></slot>
  </el-link>
  <router-link v-else
               :to="to">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: 'XRouterLink',
  props: {
    isindex: {
      type: Number
    },
    to: {
      type: String
    }
  }
}
</script>